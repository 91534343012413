import repository from './repository'

const resource = 'feeding-program-categories'

export default {
  index () {
    return repository.get(resource)
  },
  store (data) {
    return repository.post(resource, data)
  },
  stockCategory (stockCategoryId, params) {
    return repository.get(`${resource}/${stockCategoryId}/stock-category`, { params })
  },
  show (id) {
    return repository.get(`${resource}/${id}/show`)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
  validate (data) {
    return repository.post(`${resource}/validate`, data)
  },
}
