import repository from './repository'

const resource = 'customers'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  storeForecast (consumer, data) {
    return repository.post(`${resource}/${consumer}/forecast`, data)
  },
  updateForecast (consumer, data) {
    return repository.put(`${resource}/${consumer}/forecast`, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  edit (id) {
    return repository.get(`${resource}/${id}/edit`)
  },
  detail (id) {
    return repository.get(`${resource}/${id}/detail`)
  },
  orderDetails (params) {
    return repository.get(`${resource}/order-details`, { params })
  },
}
