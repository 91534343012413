const snakeToCamel = str => str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

const requestLoadingNames = request => ({
  get: 'getting',
  post: 'posting',
  put: 'putting',
  delete: 'deleting',
})[request]

const getRequestVarDefaults = (options = {}) => {
  const {
    hasData = true,
    hasLoading = true,
    hasError = true,
    pagination = false,
    dataName = 'data',
    loadingName = 'loading',
    errorName = 'error',
    countName = 'count',
  } = options
  const result = {}
  if (hasData) result[dataName] = []
  if (hasLoading) result[loadingName] = false
  if (hasError) result[errorName] = {}
  if (pagination) result[countName] = 0
  return result
}

const filterRequestVarNames = (options = {}) => {
  const {
    dataName = 'data',
    errorName = 'error',
    loadingName = 'loading',
    countName = 'count',
    hasData = true,
    hasError = true,
    hasLoading = true,
    pagination = false,
  } = options

  const result = {}
  if (hasData) result.data = dataName
  if (hasLoading) result.loading = loadingName
  if (hasError) result.error = errorName
  if (pagination) result.count = countName
  return result
}

const getRequestVarNames = (options = {}) => {
  const { identifier = null, request = 'get', loading = null, data = null, count = null, error = null, pagination = false } = options
  const dataName = data || snakeToCamel(`${identifier}-list`)
  const loadingName = loading || snakeToCamel(`${identifier}-${requestLoadingNames(request)}`)
  const errorName = error || snakeToCamel(`${identifier}-${request}-errors`)
  if (pagination) {
    const countName = count || snakeToCamel(`${identifier}-${request}-count`)
    return { dataName, loadingName, errorName, countName }
  }
  return { dataName, loadingName, errorName }
}

const modifyCustomerAttributesNames = (attributes = {}, options = {}) => {
  const { identifier = null } = options
  return Object.keys(attributes)
    .reduce((result, key) => {
      result[snakeToCamel(`${identifier}-${key}`)] = attributes[key] || null
      return result
    }, {})
}

const requestVars = (options = {}, customAttributes = {}) => {
  const varNames = getRequestVarNames(options)
  const extendedOptions = { ...options, ...varNames }
  const extendedAttributes = modifyCustomerAttributesNames(customAttributes, options)
  const result = getRequestVarDefaults(extendedOptions)
  const requestVarNames = filterRequestVarNames(extendedOptions)
  return [
    { ...result, ...extendedAttributes },
    requestVarNames,
  ]
}

const getVars = (identifier, attrs = {}, options = {}) => {
  const obj = {}
  const { list = true, loading = true, errors = true } = options
  const result = {}
  Object.keys(attrs).map(e => {
    Object.assign(obj, {
      [snakeToCamel(`${identifier}-get-${e}`)]: attrs[e],
    })
  })
  if (list) result[snakeToCamel(`${identifier}-list`)] = []
  if (loading) result[snakeToCamel(`${identifier}-getting`)] = false
  if (errors) result[snakeToCamel(`${identifier}-get-errors`)] = {}
  return {
    ...result,
    ...obj,
  }
}

const postVars = (identifier, attrs = {}) => {
  const obj = {}
  Object.keys(attrs).map(e => {
    Object.assign(obj, {
      [snakeToCamel(`${identifier}-post-${e}`)]: attrs[e],
    })
  })
  return {
    [snakeToCamel(`${identifier}-post-success`)]: null,
    [snakeToCamel(`${identifier}-posting`)]: false,
    [snakeToCamel(`${identifier}-post-errors`)]: {},
    ...obj,
  }
}

const errorHandler = function (errors) {
  let ret = ''
  if (!errors.response) {
    ret = { errors: [errors] }
  } else {
    ret = errors.response.data.errors !== undefined ? errors.response.data.errors : { error: [errors.toString()] }
  }
  return ret
}

const putVars = (identifier, attrs = {}) => {
  const obj = {}
  Object.keys(attrs).map(e => {
    Object.assign(obj, {
      [snakeToCamel(`${identifier}-put-${e}`)]: attrs[e],
    })
  })
  return {
    [snakeToCamel(`${identifier}-putting`)]: false,
    [snakeToCamel(`${identifier}-put-errors`)]: [],
    ...obj,
  }
}

export {
  // intervalGetters,
  getVars,
  postVars,
  putVars,
  requestVars,
  errorHandler,
}
