function trigger (delay) {
  const tDelay = delay
  let varTimeout
  return callback => {
    clearTimeout(varTimeout)
    varTimeout = setTimeout(callback, tDelay)
  }
}

export default function (delay = 500) {
  return trigger(delay)
}
