<template lang="pug">
  v-container
    base-snackbar(
      :errors.sync="cxFcstGetErrors"
    )
    v-toolbar(
      dark
      dense
      elevation="0"
      color='primary'
    )
      v-toolbar-title
        span Forecasts
      v-spacer
      v-btn.mr-2(
        text
        dark
        :loading="convertingToPR"
        @click="createPR"
      )
        span Generate PR
      v-icon mdi-chart-timeline-variant
    v-data-table(
      dense
      :loading="cxFcstGetting"
      :items="computedCxFcst"
      :headers="headers"
      hide-default-footer
    )
      template(v-slot:top)
        v-row
          v-col(cols="3" align="right")
            v-autocomplete(
              :items="customerList"
              v-model="customerId"
              :loading="customerGetting"
              label="Customer"
              item-value="id"
              clearable
              item-text="company"
            )
          v-col(cols="3" align="right")
            v-autocomplete(
              :items="fdPrgmCategoryList"
              v-model="type"
              label="Types"
              clearable
              :loading="fdPrgmCategoryGetting"
              item-value="id"
              item-text="description"
            )
          v-col(cols="3" align="right")
            v-text-field(
              label="Date Start"
              type="date"
              clearable
              v-model="dateStart"
            )
          v-col(cols="3" align="right")
            v-text-field(
              label="Date End"
              type="date"
              clearable
              v-model="dateEnd"
            )
      template(v-slot:item="{ item }")
        tr
          td {{ item.stock.description || 'N/A' }}
          td(align="right") {{ item.feedingProgramDetails.bags || 'N/A' }} {{ item.feedingProgramDetails.bags > 1 ? 'bags' : 'bag' }}
          td(align="right") {{ (item.feedDetail.perBag || 0).phpFormat() || 'N/A' }}
          td(align="right") {{ (item.feedDetail.resellPerBag || 0).phpFormat() || 'N/A' }}
          td(align="right") {{ (item.feedingProgramDetails.costBags || 0).phpFormat() || 'N/A' }}
          td(align="right") {{ (item.feedingProgramDetails.resellBags || 0).phpFormat() || 'N/A' }}
      template(v-slot:body.append)
        tr
          td(colspan="4" align="right")
            strong Total
          td(align="right") {{ totalCostBags.phpFormat() }}
          td(align="right") {{ totalResellBags.phpFormat() }}
</template>

<style lang="scss" scope>
  .border-bottom {
    border-bottom: 1px solid lightgray;
  }
  .border-top {
    border-top: 1px solid lightgray;
  }
  .border-left {
    border-left: 1px solid lightgray;
  }
  .border-right {
    border-right: 1px solid lightgray;
  }
</style>

<script>
import feedingProgramCategoryRepository from '@/repositories/feeding-program-category.repository'
import customerRepository from '@/repositories/customer.repository'
import customerForecastTypeRespository from '@/repositories/customer-forecast-type.respository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [customerForecastTypeGetVars, customerForecastTypeGetVarNames] = requestVars({ identifier: 'cx-fcst' })
const [customerGetVars, customerGetVarNames] = requestVars({ identifier: 'customer' })
const [feedingProgramCategoryGetVars, feedingProgramCategoryGetVarNames] = requestVars({ identifier: 'fd-prgm-category' })

const findDistinctKeyValues = (items, key) => items.filter((e, i) => items.findIndex(el => e[key] === el[key]) === i).map(e => e[key])

const calculateTotalQuantities = items => items.reduce((a, b) => a + b.feeding_program_details.bags, 0)

const calculateTotalCostBags = items => items.reduce((a, b) => a + b.feeding_program_details.cost_bags, 0)

const calculateTotalResellBags = items => items.reduce((a, b) => a + b.feeding_program_details.resell_bags, 0)

const getDataAttributes = (items, key) => e => {
  const keyItems = items.filter(el => el[key] === e)
  const quantity = calculateTotalQuantities(keyItems)
  const totalCostBags = calculateTotalCostBags(keyItems)
  const totalResellBags = calculateTotalResellBags(keyItems)
  const keyItem = keyItems.first()
  const costPerBag = keyItem.feed_detail.per_bag
  const resellPerBag = keyItem.feed_detail.resell_per_bag
  const stockName = keyItem.stock.description

  return {
    quantity,
    stockName,
    costPerBag,
    resellPerBag,
    totalCostBags,
    totalResellBags,
  }
}

const currentDate = () => new Date().toLocaleString('sv-SE', { year: 'numeric', day: '2-digit', month: '2-digit' })

const groupForecastData = (items, key) => {
  const keys = findDistinctKeyValues(items, key)
  return keys.map(getDataAttributes(items, key))
}

const inputVars = () => ({
  customerId: null,
  type: null,
  dateStart: currentDate(),
  dateEnd: null,
})

const tableVars = () => ({
  headers: [
    { text: 'Stock', value: 'stock_id' },
    { text: 'Quantity', value: 'feeding_program_details.bags' },
    { text: 'Cost/Bag', value: 'feed_detail.per_bag' },
    { text: 'Resell/Bag', value: 'feed_detail.resell_per_bag' },
    { text: 'Cost Amount', value: 'feeding_program_details.cost_bags' },
    { text: 'Resell Amount', value: 'feeding_program_details.resell_bags' },
  ],
})

export default {
  name: 'CustomerForecastTable',
  data: () => ({
    ...customerForecastTypeGetVars,
    ...customerGetVars,
    ...feedingProgramCategoryGetVars,
    ...tableVars(),
    ...inputVars(),
  }),
  created () {
    this.getCustomerForecastTypes()
    this.getCustomers()
    this.getFeedingProgramCategory()
  },
  computed: {
    feedingProgramCategoryGetHandler () {
      return new VueRequestHandler(this, feedingProgramCategoryGetVarNames)
    },
    customerForecastTypeGetHandler () {
      return new VueRequestHandler(this, customerForecastTypeGetVarNames)
    },
    customerGetHandler () {
      return new VueRequestHandler(this, customerGetVarNames)
    },
    totalCostBags () {
      return this.computedCxFcst.reduce((a, b) => a + b.feedingProgramDetails.costBags, 0)
    },
    totalResellBags () {
      return this.computedCxFcst.reduce((a, b) => a + b.feedingProgramDetails.resellBags, 0)
    },
    computedCxFcst () {
      return this.cxFcstList.map(item => Object.keys(item).reduce((result, key) => {
        const key2 = key.snakeToCamelCase()
        if (typeof item[key] === 'object') {
          result[key2] = this.$objectChangeKeyCase(item[key])
          return result
        }
        result[key2] = item[key]
        return result
      }, {}))
    },
  },
  watch: {
    customerId () {
      this.getCustomerForecastTypes()
    },
    type () {
      this.getCustomerForecastTypes()
    },
    dateStart () {
      this.getCustomerForecastTypes()
    },
    dateEnd () {
      this.getCustomerForecastTypes()
    },
  },
  methods: {
    getCustomers () {
      const handler = this.customerGetHandler
      const repository = customerRepository.index
      const has = 'forecast'
      handler.execute(repository, [{ has }])
    },
    getFeedingProgramCategory () {
      const handler = this.feedingProgramCategoryGetHandler
      const repository = feedingProgramCategoryRepository.index
      handler.execute(repository)
    },
    getCustomerForecastTypes () {
      const handler = this.customerForecastTypeGetHandler
      const repositoy = customerForecastTypeRespository.summary
      const params = this.getCustomerForecastTypesQueryString()
      handler.execute(repositoy, [params])
    },
    getCustomerForecastTypesQueryString () {
      return Object.keys(inputVars())
        .reduce((result, key) => {
          result[key] = this[key]
          return result
        }, {})
    },
  },
}

</script>