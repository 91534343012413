import searchDelay from '@/libs/searchDelay.extra'
import { errorHandler } from '@/libs/api-helper.extra'
// handler for repositories-2
export default class VueRequestHandler {
  constructor (Vue, { loading = null, data = null, error = null, count = null }, conditions = {}, pagination = false) {
    const { hasLoading = true, hasList = true, hasError = true, delay = 500 } = conditions || {}
    this.Vue = Vue
    this.setDelay(delay)
    this.setPaginationMode(pagination)
    if (pagination) this.setDataCountVariable(count)
    if (hasLoading) this.setLoadingVariableName(loading)
    if (hasList) this.setListVariableName(data)
    if (hasError) this.setErrorVariableName(error)
  }

  setDataCountVariable (count) {
    this.dataCountVariable = count
  }

  setPaginationMode (pagination) {
    this.pagination = pagination
  }

  setVue (Vue) {
    this.Vue = Vue
  }

  setDelay (delay) {
    this.delay = searchDelay(delay)
  }

  setListVariableName (name) {
    this.dataVariable = name
  }

  setErrorVariableName (name) {
    this.errorVariable = name
  }

  setLoadingVariableName (name) {
    this.loadingVariable = name
  }

  setLoading (isloading = true) {
    this.Vue[this.loadingVariable] = isloading
  }

  get getLoading () {
    return this.Vue[this.loadingVariable]
  }

  setList (data) {
    if (this.pagination) {
      const { list, count } = data
      this.Vue[this.dataVariable] = list
      this.Vue[this.dataCountVariable] = count
      return
    }
    this.Vue[this.dataVariable] = data
  }

  setErrors (e) {
    this.Vue[this.errorVariable] = errorHandler(e)
  }

  clearErrors () {
    this.Vue[this.errorVariable] = []
  }

  execute (repository = () => {}, args = [], statusOkCallback = null, statusErrorCallback = null, afterRequestCallback = null) {
    this.setLoading(true)
    this.clearErrors()
    return new Promise((resolve, reject) => {
      this.delay(() => {
        repository(...args)
          .then(response => {
            if (this.dataVariable) this.setList(response.data)
            if (statusOkCallback instanceof Function) statusOkCallback(response)
            resolve(response)
          })
          .catch(e => {
            if (this.errorVariable) this.setErrors(e)
            if (statusErrorCallback instanceof Function) statusErrorCallback(e)
            reject(e)
          })
          .then(() => {
            if (this.loadingVariable) this.setLoading(false)
            if (afterRequestCallback instanceof Function) afterRequestCallback()
          })
      })
    })
  }
}