import repository from './repository'

const resource = 'customer-forecast-types'

export default {
  summary (params) {
    return repository.get(`${resource}/summary`, { params })
  },
  store (data) {
    return repository.post(resource)
  },
  put (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
}
