import axios from 'axios'
import store from '@/store'

const { token } = store.state.auth

const appAxios = axios.create({
  baseURL: `${process.env.VUE_APP_SERVER_DOMAIN}/api`,
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
})
export default appAxios
